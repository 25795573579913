@font-face {
  font-family: 'Amazon Ember Display';
  src: url('../fonts/AmazonEmberDisplay_Rg.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Amazon Ember Display';
  src: url('../fonts/AmazonEmberDisplay_Lt.ttf') format('truetype');
  font-weight: lighter;
}

@font-face {
  font-family: 'Amazon Ember Display';
  src: url('../fonts/AmazonEmberDisplay_Bd.ttf') format('truetype');
  font-weight: bold;
}
