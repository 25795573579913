@import './variables';

#ccc * {
  font-family: 'Amazon Ember Display', Arial, sans-serif !important;
}

// icon
#ccc-icon {
  height: 64px !important;
  width: 64px !important;
  text-align: left;
  content: '' !important;
  background-image: url('../images/civic/icon_cookie-full-desktop.svg') !important;
  background-size: cover !important;

  svg {
    display: none;
  }

  @media screen and (min-width: 1200px) {
    padding: 0px !important;
    height: 80px !important;
    width: 80px !important;
  }
}

// notify
#ccc-notify {
  background: linear-gradient(180deg, $color-squid-ink 0%, #003181 431.25%);
  box-shadow: 0px -2px 20px 0px rgba(35, 47, 62, 0.64);
  padding: 32px 40px !important;

  @media screen and (min-width: 1020px) {
    align-items: center !important;
  }

  @media screen and (min-width: 1200px) {
    padding: 0px 130px 0px 90px !important;
    height: 80px !important;
    left: 35px !important;
  }

  .ccc-notify-text {
    padding-right: 50px !important;

    h1 {
      font-size: 18px !important;
      font-weight: 700;
    }

    p {
      font-size: 16px !important;
      margin-top: 8px !important;

      @media screen and (min-width: 1200px) {
        margin-top: 0 !important;
      }
    }
  }

  .ccc-notify-buttons {
    margin: 0 !important;
  }

  .ccc-notify-button {
    background-color: $color-white !important;
    border-radius: 100px !important;
    margin: 24px 0 0 !important;

    @media screen and (min-width: 1020px) {
      margin: 0 !important;
    }

    span {
      color: $color-squid-ink !important;
      font-weight: 700 !important;
      font-size: 13px !important;
    }
  }

  .ccc-accept-button {
    margin-right: 20px !important;
  }
}

//content

#ccc-module.ccc-module--slideout {
  width: 95% !important;
}

#ccc-content {
  background-color: $color-white !important;
  filter: drop-shadow(0px -2px 20px rgba(35, 47, 62, 0.64));

  * {
    color: $color-squid-ink !important;
  }

  #cc-panel {
    top: 40px !important;
    left: 40px !important;
    right: 40px !important;
  }

  #ccc-close {
    top: 40px;
    right: 40px;
    width: 40px;
    height: 40px;
    margin-left: 28px;
    content: url('../images/civic/icon-close-blue.svg');
  }

  #ccc-title {
    font-size: 23px !important;
    line-height: 28px !important;
  }

  #ccc-title::before {
    content: url('../images/civic/icon_cookie.svg');
    display: block;
    margin-bottom: 36px;
  }

  .ccc-intro {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    margin: 24px 0 20px !important;
  }

  #ccc-statement {
    margin-bottom: 40px;

    p {
      font-size: 16px !important;
      font-weight: 400 !important;
      line-height: 24px !important;
    }
  }

  #ccc-button-holder,
  hr {
    display: none;
  }

  #ccc-necessary-title,
  .optional-cookie-header {
    font-size: 16px !important;
    font-weight: 700 !important;
    line-height: 20px !important;
  }

  #ccc-necessary-description,
  .optional-cookie p {
    font-size: 14px !important;
    line-height: 21px !important;
    margin: 8px 0 32px !important;
  }

  .optional-cookie p {
    width: 202px !important;

    @media screen and (min-width: 1020px) {
      width: 232px !important;
    }
  }

  #ccc-optional-categories {
    .checkbox-toggle {
      top: 50%;
      right: 0;
    }

    .checkbox-toggle-toggle {
      background-color: $color-white !important;
    }

    .checkbox-toggle-on,
    .checkbox-toggle-off {
      color: $color-white !important;
      margin: 0 !important;
      padding: 4px 3px !important;
      opacity: 1 !important;
      text-transform: uppercase !important;

      @media screen and (min-width: 1020px) {
        padding: 1px 0px !important;
      }
    }
  }

  #ccc-notify-dismiss {
    display: none;
    left: -10000px;
  }
}
