@mixin flex($direction, $justify, $align) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin mobile {
  @media screen and (max-width: $tablet) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: $tablet) {
    @content;
  }
}

@mixin tablet-large {
  @media screen and (min-width: $tablet-large) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop) {
    @content;
  }
}
