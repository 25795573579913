@import 'fonts';
@import 'common';
@import 'variables';
@import './civic.scss';

body {
  font-family: 'Amazon Ember Display', Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

* {
  margin: 0;
}

.hide {
  display: none;
}

.header {
  background-color: $color-squid-ink;
  height: 69.3px;
  padding: 0 40px;
  @include flex(row, flex-start, center);

  img {
    width: 59px;
  }
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.section-1 {
  background-image: url(../images/header-mobile.webp);
  background-size: cover;
  background-position: center;
  height: 414px;
  @include flex(row, center, center);

  @include tablet {
    background-image: url(../images/header-tablet.webp);
    height: 294px;
  }

  @include desktop {
    background-image: url(../images/header-desktop.webp);
  }

  &_text {
    @include flex(column, center, flex-start);
    width: 100%;
    padding: 0 60px;

    @include tablet {
      padding: 0 70px;
    }

    h1,
    h2 {
      color: $color-white;
      font-style: normal;
      max-width: 700px;
    }

    h1 {
      font-size: 34px;
      font-weight: 700;
      line-height: 1.3;
      margin-bottom: 16px;

      @include desktop {
        font-size: 40px;
        line-height: 1.2;
      }
    }

    h2 {
      font-size: 24px;
      font-weight: 500;
      line-height: 1.3;
    }
  }
}

.section-2 {
  padding: 40px 40px 0px;

  h3,
  p {
    color: $color-squid-ink;
    font-style: normal;
  }

  h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 8px;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
  }

  &_intro {
    padding-bottom: 20px;
  }

  &_label {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.57;
    color: $color-squid-ink;
    padding: 10px;
    margin-bottom: 16px;

    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
      0 1px 2px -1px rgba(0, 0, 0, 0.1);
  }

  &_cards {
    @include flex(column, center, center);
    padding: 20px 10px;

    @include tablet {
      @include flex(row, center, flex-start);
      gap: 16px;
    }
  }

  &_col {
    flex: 1;
    width: 100%;
    max-width: 314px;
    margin: 20px auto;

    @include tablet {
      max-width: 324px;
      margin: 0 0 30px;
    }

    @include desktop {
      max-width: 570px;
    }
  }

  .section-2-card {
    position: relative;
    @include flex(column, center, center);

    &_image,
    &_video {
      width: 100%;
      object-fit: cover;
      height: 180px;
      display: block;

      @include desktop {
        height: 320px;
      }
    }

    &_play {
      position: absolute;
      width: 62px;
      cursor: pointer;

      @include desktop {
        width: 110px;
      }
    }
  }
}

.section-3 {
  padding: 20px 40px;

  h3,
  h4,
  p {
    color: $color-squid-ink;
    font-style: normal;
  }

  h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.5;
    margin-bottom: 8px;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
  }

  &_grid {
    margin: 40px 0 0;
    padding: 0 10px;

    @include tablet {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      margin: 40px 0;
    }

    @include desktop {
      margin: 40px 0 80px;
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .section-3-card {
    padding: 16px;
    margin: 20px 0;
    border: 1px solid $color-grey;

    @include tablet {
      margin: 0;
    }

    &_top {
      @include flex(row, center, center);
      height: 120px;
      padding-bottom: 16px;
    }

    &_content {
      padding-bottom: 16px;

      h4 {
        font-size: 18px;
        font-weight: 700;
        line-height: 1.6;
        margin-bottom: 15px;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.56;
        margin-bottom: 15px;
      }

      a {
        color: #2074d5;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.57;
        text-decoration: underline;
      }
    }
  }
}

footer {
  height: 196px;

  .footer-1 {
    background-color: $color-squid-ink;
    padding: 32px 40px;
    color: $color-white;

    &_socials {
      @include flex(row, flex-start, center);
      margin-bottom: 8px;

      img {
        margin-right: 17.5px;
      }
    }

    p {
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.69;
    }
  }

  .footer-2 {
    background-color: $color-grey;
    padding: 16px 40px;

    span {
      color: $color-squid-ink;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.69;

      a {
        text-decoration: none;
        color: $color-squid-ink;
      }
    }
  }
}

.no-webp {
  .section-1 {
    background-image: url(../images/header-mobile.jpg);

    @include tablet {
      background-image: url(../images/header-tablet.jpg);
    }

    @include desktop {
      background-image: url(../images/header-desktop.jpg);
    }
  }
}
